/********************************************/
/* PLACEHOLDERS TO EXTEND THE CSS SELECTORS */
/********************************************/
%left { float: left; }
%right {float: right; }
%floatnone {float: none; }
%relative { position: relative; }
%none { display: none; }
%textCenter {text-align: center; }
%nomargin { margin: 0; }
%margin0auto { margin: 0 auto; }
%marginauto { margin: auto; }
%marginBottom10 { margin-bottom: 10px; }
%marginBottom20 { margin-bottom: 20px; }
%marginBottom30 { margin-bottom: 30px; }
%marginBottom40 { margin-bottom: 40px; }
%marginBottom50 { margin-bottom: 50px; }
%marginBottom70 { margin-bottom: 70px; }
%marginBottom100 { margin-bottom: 100px; }
%nopadding { padding: 0; }
%paddingSides10 {padding: 0 10px; }
%noborder { border: 0; }
%nobackground { background: none!important; }
%nofloat { float: none; }
%width100 { width: 100%; }
%minWidth0 { min-width: 0; }
%minWidth980 { min-width: 980px; }
%maxWidthNone { max-width: none; }
%maxWidth980 { max-width: 980px; }
%maxWidth1400 { max-width: 1400px; }
%maxWidth1920 { max-width: 1920px; }
%fontSize0 { font-size: 0; }
%displayIB { display: inline-block; }
%height100 { height: 100%; }
%displayIBTop {
    display: inline-block;
    vertical-align: top;
}
%displayIBMiddle {
    display: inline-block;
    vertical-align: middle;
}
%placeholder {
    color: #dfdfdf;
    letter-spacing: -1px;
    font-style: italic;
} 

%absolute0 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
%pointer { cursor: pointer; }