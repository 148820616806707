@font-face {
	font-family: 'bold';
	src: local('Poppins Bold'), local('Poppins-Bold'),
		url('../fonts/Poppins-Bold.woff2') format('woff2'),
		url('../fonts/Poppins-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'regular';
	src: local('Poppins Regular'), local('Poppins-Regular'),
		url('../fonts/Poppins-Regular.woff2') format('woff2'),
		url('../fonts/Poppins-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'medium';
	src: local('Poppins Medium'), local('Poppins-Medium'),
		url('../fonts/Poppins-Medium.woff2') format('woff2'),
		url('../fonts/Poppins-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'light';
	src: local('Poppins Light'), local('Poppins-Light'),
		url('../fonts/Poppins-Light.woff2') format('woff2'),
		url('../fonts/Poppins-Light.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
