/*MAIN BREAKPOINT */
$breakpoint: 700px;

/* GENERALS COLORS */
$black: #000;
$white: #fff;
$red: #f30c53;

$borderColor: #232b35;

$textColor: #232b35;

$highlightColor: #00f;
$highlightColor2: #00c;

$bgColor: #e0e0db;

$linkColor: $textColor;
$linkColorHover: $black;
$borderLinkColor: $white;
