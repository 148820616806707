@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css');
@import '_extends.scss';
@import '_mixins.scss';
@import '_variables.scss';
@import '_fonts.scss';
@import '_fontfamilies.scss';

* {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
	color: $textColor;
	background: $bgColor;
	font: normal 18px/1.6 font-family($font-default);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;

	@media (max-width: $breakpoint) {
		font-size: 24px;
	}

	&.overflowHidden {
		overflow: hidden;
	}
}

a {
	@extend %relative;
	transition: color 0.1s;
	text-decoration: none;
	color: $highlightColor2;

	&.ob-link {
		@media (max-width: 400px) {
			white-space: nowrap;
			font-size: 7vw;
		}
	}

	@media (hover: hover) {
		&:hover {
			color: $linkColorHover;

			&:before {
				border-color: transparent;
			}
		}
	}
}

/* SHOW CONTENT */
// .wrapper {
// 	transition: opacity 1s;
// 	opacity: 0;
// }

// .show-html .wrapper {
// 	opacity: 1;
// }

/* BASIC */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
	margin: 0 0 20px;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.4;
	font-family: font-family($font-default, medium);
	font-weight: normal;
}

h1 {
	font-size: 150%;

	@media (max-width: 350px) {
		font-size: 10vw;
	}
}
h2 {
	font-size: 125%;
}
h3 {
	font-size: 110%;
}
h4 {
	font-size: 100%;
}
h5 {
	font-size: 80%;
}
h6 {
	font-size: 60%;
}

img {
	@extend %width100;
	height: auto;
}

/* HELPER CLASSES */
.marginBottom10 {
	@extend %marginBottom10;
}
.marginBottom20 {
	@extend %marginBottom20;
}
.marginBottom30 {
	@extend %marginBottom30;
}
.marginBottom40 {
	@extend %marginBottom40;
}
.marginBottom50 {
	@extend %marginBottom50;
}
.marginBottom70 {
	@extend %marginBottom70;
}
.marginBottom100 {
	@extend %marginBottom100;
}

.center {
	text-align: center;
}

.lineright {
	display: flex;
	width: 100%;
	align-items: center;
	// justify-content: center;
	// text-align: center;

	&:after {
		content: '';
		border-top: 1px solid rgba($borderColor, 0.25);
		margin-left: 30px;
		flex: 1;
	}
}

.max-width {
	max-width: 1200px;
	margin: 0 auto;
}

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.flex-horizontal {
	display: flex;
}

.flex-vertical {
	display: flex;
	flex-direction: column;
}

.col {
	padding: 0 10px;

	&.col-50 {
		width: 50%;
	}
}

.hidden {
	@extend %none;
}

.header {
	@extend %width100;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	background: rgba($bgColor, 0.75);
	z-index: 2;

	@media (max-width: $breakpoint) {
		&.menuIsVisible {
			bottom: 0;

			.menu-trigger {
				background: url(../images/icon-close.svg) no-repeat
					center center/25px;
			}

			.menu {
				.menu-right {
					display: flex;
					flex-direction: column;
					justify-content: center;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					position: fixed;
					overflow-y: auto;
					background: $bgColor;
					z-index: 3;
				}
			}
		}
	}

	.menu-trigger {
		@extend %none;
		@extend %noborder;
		@extend %pointer;
		@extend %fontSize0;
		border-radius: 0;
		position: absolute;
		top: 0;
		right: 0;
		width: 80px;
		height: 80px;
		background: rgba(white, 0);
		justify-content: center;
		align-items: center;
		text-align: center;
		z-index: 10;
		background: url(../images/icon-menu.svg) no-repeat center
			center/25px;

		&:focus {
			outline: none;
		}

		@media (max-width: $breakpoint) {
			display: flex;
		}
	}

	.menu {
		display: flex;
		align-items: center;
		max-width: 1220px;
		margin: 0 auto;
		padding: 25px 19px;

		.menu-left {
			flex: 1;
			display: flex;
			align-items: center;
		}

		.logo {
			@extend %fontSize0;
			text-decoration: none;
			width: 162px;
			height: 40px;
			background: url(../images/logo.svg) no-repeat center
				center/138px 19px;

			&:before,
			&:after,
			&.active:after {
				@extend %none;
			}
		}

		.menu-right {
			display: flex;
			align-items: center;

			@media (max-width: $breakpoint) {
				display: none;
			}

			a,
			button {
				@extend %relative;
				@extend %displayIBTop;
				padding: 9px;
				font-size: 14px;
				line-height: 22px;
				text-decoration: none;
				color: $textColor;

				@media (max-width: $breakpoint) {
					margin: 8px auto;
					display: block;
					font-size: 24px;
					padding: 25px 60px;
				}

				&:before {
					@extend %none;
				}

				&.lang {
					@extend %noborder;
					@extend %pointer;
					margin: 0 17px;
					padding: 3px 6px;
					border-radius: 0;
					font-family: font-family($font-default, bold);
					font-size: 9px;
					line-height: 11px;
					text-transform: uppercase;
					background: $highlightColor;
					color: $white;

					&:focus {
						outline: none;
					}

					@media (max-width: $breakpoint) {
						margin: 8px auto;
						display: block;
						font-size: 19px;
						padding: 25px 60px;
					}
				}

				&.active {
					@media (max-width: $breakpoint) {
						border: 2px solid $highlightColor;
					}

					&:after {
						display: block;
						content: '';
						position: absolute;
						top: 0;
						left: 7px;
						width: 20px;
						height: 2px;
						background: $white;

						@media (max-width: $breakpoint) {
							display: none;
						}
					}
				}
			}
		}
	}
}

.main {
	@extend %width100;
	flex-direction: column;
	display: flex;
	flex: 1;
	padding: 125px 15px;
	max-width: 1200px;
	margin: 0 auto;

	.loader {
		@extend %width100;
		@extend %marginauto;
		@extend %fontSize0;
		display: flex;
		flex-direction: column;
		flex: 1;
		max-width: 500px;
		padding: 125px 15px;
		align-items: center;
		justify-content: center;

		.loader-symbol {
			display: inline-flex;
			border: 3px solid rgba(0, 0, 0, 0.1);
			border-radius: 50%;
			border-top: 3px solid rgba(0, 0, 0, 0.75);
			width: 40px;
			height: 40px;
			-webkit-animation: spin 1s linear infinite; /* Safari */
			animation: spin 1s linear infinite;
		}

		/* Safari */
		@-webkit-keyframes spin {
			0% {
				-webkit-transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
			}
		}

		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}

	.index {
		justify-content: space-between;

		@media (max-width: $breakpoint) {
			flex-direction: column;
		}

		.col-1 {
			width: 450px;

			@media (max-width: $breakpoint) {
				order: 2;
				width: 100%;
			}
		}

		.col-2 {
			width: 550px;

			@media (max-width: $breakpoint) {
				order: 1;
				width: 100%;
			}
		}
	}
}

.footer {
	background: $white;

	.max-width {
		display: flex;
		justify-content: space-between;
		padding: 0 10px;

		@media (max-width: $breakpoint) {
			flex-direction: column;
		}
	}

	.col {
		display: flex;
		flex-direction: column;
		font-size: 88%;
		line-height: 1.6;
		padding: 55px 17px;

		.group {
			flex: 1;
		}

		h4 {
			margin: 0 0 17px;
			font: normal 88%/1.1 font-family($font-default, bold);
			text-transform: uppercase;
		}

		p:last-of-type {
			margin-bottom: 0;
		}

		.footerNote {
			background: rgba($highlightColor, 0.05);
			padding: 0 4px;
			border-radius: 1px;
		}
	}

	a {
		&:before {
			border-color: rgba($textColor, 0.2);
		}
	}

	.credits {
		font-size: 65%;
		> a {
			color: rgba($textColor, 0.25);

			&:before {
				@extend %none;
			}
		}
	}
}

/* INDEX */
.asterisk {
	@extend %width100;
	height: 40px;
	background: url(../images/icon-asterisk.svg) no-repeat 0
		center/40px;

	&.lineright:after {
		margin-left: 70px;
	}
}

.arrow {
	transition: background-color 0.2s;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: rgba($textColor, 0.03) url(../images/icon-arrow.svg)
		no-repeat center center/38px;

	@media (hover: hover) {
		&:hover {
			background-color: rgba($highlightColor, 0.9);
		}
	}
}

/* INDEX MAP */
.index .map {
	@extend %fontSize0;
	padding: 5px;
	background: $textColor;
}

.map-button {
	@extend %displayIBTop;
	@extend %pointer;
	transition: background 0.1s, color 0.1s;
	padding: 9px 24px;
	font-size: 60%;
	color: $white;
	background: $textColor;
	text-transform: uppercase;

	&:before {
		@extend %none;
	}
	@media (max-width: $breakpoint) {
		font-size: 55%;
	}

	@media (hover: hover) {
		&:hover {
			background: transparent;
		}
	}
}

.index {
	.col-2 {
		h1 {
			font-size: 140%;
			font-family: font-family($font-default, bold);
		}

		h2 {
			font-size: 130%;
		}

		p {
			font: normal 110%/2 font-family($font-default);
			white-space: pre-wrap;

			&.bold {
				font-family: font-family($font-default, bold);
			}
		}
	}

	ul,
	ol {
		line-height: 1.5;

		ul {
			padding: 10px 25px 5px;
		}

		li {
			margin-bottom: 10px;
		}
	}

	ol {
		@extend %marginBottom30;
		padding: 0 0 0 25px;
	}
}

/* DESCRIPTION INDEX */
.description.index {
	max-width: 850px;
	@extend %margin0auto;
}

.price {
	@extend %displayIB;
	@extend %margin0auto;
	padding: 6px 12px;
	background: $white;
	border-radius: 2px;
	text-align: center;
}

/* PROGRAM + NOTFOUND */
.program,
.notfound {
	max-width: 750px;

	.lineright,
	.table {
		padding: 0 15px;
	}

	.row + .program-month {
		padding-top: 30px;
	}

	.row {
		display: flex;

		@media (max-width: $breakpoint) {
			flex-direction: column;
			padding-bottom: 20px;
			border-bottom: 1px dotted $white;
		}
	}

	.program-month {
		text-transform: capitalize;
	}

	span {
		@extend %displayIB;
	}

	.date {
		width: 270px;
		margin-right: 17px;

		@media (max-width: $breakpoint) {
			width: 100%;
			margin-right: 0;
		}
	}

	.artist {
		width: calc(100% - 287px);
		display: flex;
		align-items: center;

		@media (max-width: $breakpoint) {
			width: 100%;
			flex-direction: column;
			align-items: flex-start;
			flex-wrap: wrap;
		}

		strong {
			// display: inline-flex;
			margin-right: 17px;

			@media (max-width: $breakpoint) {
				margin-bottom: 10px;
			}

			.sep {
				font-family: font-family($font-default, light);
				font-style: normal;
				display: inline-flex;
				padding: 0 1px;
				opacity: 0.3;
			}
		}

		em {
			display: inline-flex;
			padding: 3px 6px;
			font-style: normal;
			// font-size: 9px;
			// line-height: 11px;
			font-size: 55%;
			line-height: 1.1;
			text-transform: uppercase;
			font-family: font-family($font-default, bold);
			background: rgba($black, 0.075);
			color: $linkColor;
		}
	}
}
